
import {
  defineComponent,
  ref,
  unref,
  toRefs,
  onMounted,
  watch,
  computed
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import {
  ElMessage,
  ElForm
} from 'element-plus';
import { RuleItem, Rule } from 'async-validator';
import {
  getClassifications,
  getBlocks,
  MutateTopicData,
  UPDATED_SUCCESSFULLY_TEXT,
  CREATED_SUCCESSFULLY_TEXT,
  MAX_PER_PAGE
} from '@/services/api';
import { useCreateTopic, useTopic, useUpdateTopic } from '@/composables/api';
import { Work, TopicType, TopicStyle, VideoStatus } from '@/interfaces';
import useFormErrors from '@/utils/validate';
import ChooseWorks, { SelectWorkEvent } from './ChooseWorks.vue';
import SectionLayout from '@/components/SectionLayout.vue';
import ImageUploader, { UploadImageEvent } from '@/components/ImageUploader-v2.vue';
import { cloneDeep, get, includes } from 'lodash';

const DEFAULT_FORM_VALUES: MutateTopicData = {
  classificationIds: [],
  blockIds: [],
  type: null,
  name: {
    'zh-TW': '',
    'en-US': ''
  },
  sort: 0,
  style: TopicStyle.MEDIUM,
  workIds: [],
  bannerPhotoId: null,
  thumbnailPhotoId: null,
  borderPhotoId: null
};

export const typeTranslate = {
  CUSTOM: '自訂',
  EXCLUSIVE: '麻豆獨家',
  BILLBOARD: '前十大熱榜',
  RECOMMEND: '專屬推薦',
  NEWEST: '最新上映',
  WATCH: '觀看紀錄',
  FAVORITE: '最愛'
};

const styleTranslate = {
  MEDIUM: '小圖',
  BILLBOARD: '排行',
  IMAGE_TEXT: '圖文'
};

const types = Object
  .entries(TopicType)
  .map(([label, value]) => ({ label: typeTranslate[label], value }));

const styles = Object
  .entries(TopicStyle)
  .map(([label, value]) => ({ label: styleTranslate[label], value }));

const validateClassificationIds = (classificationIds: any) =>
  (classificationIds.length !== 0);

const REQUIRED_RULE: Rule = {
  required: true,
  message: '此為必填欄位'
};

const rules = {
  classificationIds: [
    {
      required: true,
      message: '此為必填欄位',
      trigger: 'blur',
      validator: (rule: RuleItem, classificationIds: any) => {
        if (!rule.required) {
          return true;
        }
        return validateClassificationIds(classificationIds);
      }
    }
  ],
  blockIds: [REQUIRED_RULE],
  type: [REQUIRED_RULE],
  name: {
    'zh-TW': [REQUIRED_RULE],
    'en-US': [REQUIRED_RULE]
  },
  sort: [REQUIRED_RULE],
  topWorkIds: [REQUIRED_RULE],
  style: [REQUIRED_RULE]
};

export default defineComponent({
  props: {
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ChooseWorks,
    ImageUploader,
    SectionLayout
  },
  setup(props) {
    const { isEdit } = toRefs(props);
    const page = ref(1);
    const router = useRouter();
    const topicId = useRoute().params.id as string;

    const formRef = ref<typeof ElForm>();
    const formValues = ref(cloneDeep(DEFAULT_FORM_VALUES));
    const { formErrors, bindFormItemError } = useFormErrors();
    const isFormLoading = ref(true);
    const isClassificationLoading = ref(true);

    const classifications = ref([]);
    const blocks = ref([]);

    const { data, dataUpdatedAt } = useTopic({ topicId }, {
      enabled: isEdit.value,
      refetchOnWindowFocus: !isEdit.value
    });
    const { isLoading: isCreating, mutate: create } = useCreateTopic();
    const { isLoading: isUpdating, mutate: update } = useUpdateTopic();

    const thumbnailPhotoSource = computed(() => formValues.value.thumbnailPhoto?.path);
    const bannerPhotoSource = computed(() => formValues.value.bannerPhoto?.path);
    const borderPhotoSource = computed(() => formValues.value.borderPhoto?.path);

    const handleWorkIdChange = (event: SelectWorkEvent) => {
      formValues.value.workIds = event;
    };

    const handleToggleWorks = (event: SelectWorkEvent) => {
      formValues.value.workIds = event;
    };

    const handleThumbnailPhotoCrop = (image: UploadImageEvent) => {
      formValues.value.thumbnailPhoto = image;
      formValues.value.thumbnailPhotoId = image.id;
    };
    const handleBannerPhotoCrop = (image: UploadImageEvent) => {
      formValues.value.bannerPhoto = image;
      formValues.value.bannerPhotoId = image.id;
    };
    const handleBorderPhotoCrop = (image: UploadImageEvent) => {
      formValues.value.borderPhoto = image;
      formValues.value.borderPhotoId = image.id;
    };

    const handleChangeTopWorkIds = (event: number[]) => {
      formValues.value.topWorkIds = event;
    };

    const handleClassificationIdsChange = (e) => {
      if (includes(e, 4)) {
        formValues.value.classificationIds = [4];
      }
    };

    watch(dataUpdatedAt, () => {
      const topic = data.value.data;

      formValues.value = {
        id: topic.id,
        classificationIds: topic.classifications.map(classification => classification.id),
        blockIds: topic.blocks.map(block => {
          return block.id;
        }),
        topWorkIds: topic.topWorkIds,
        type: topic.type,
        name: {
          'en-US': topic.name['en-US'],
          'zh-TW': topic.name['zh-TW']
        },
        sort: topic.sort,
        style: topic.style,
        workIds: topic.works.map(work => {
          return work.id;
        }),
        thumbnailPhoto: get(topic, 'thumbnailPhoto'),
        thumbnailPhotoId: get(topic, 'thumbnailPhoto.id'),
        bannerPhoto: get(topic, 'bannerPhoto'),
        bannerPhotoId: get(topic, 'bannerPhoto.id'),
        borderPhoto: get(topic, 'borderPhoto'),
        borderPhotoId: get(topic, 'borderPhoto.id')
      };

      if (topic.classifications.length === 0) {
        formValues.value.classificationIds = null;
      }

      isFormLoading.value = false;
      isClassificationLoading.value = false;
    });

    watch(
      () => formValues.value.style,
      (newStyle) => {
        if (newStyle === 'medium') {
          formValues.value.borderPhoto = get(data.value, 'data.borderPhoto');
          formValues.value.borderPhotoId = get(data.value, 'data.borderPhoto.id', null);
          return;
        };

        formValues.value.borderPhoto = undefined;
        formValues.value.borderPhotoId = null;
      }
    );

    const fetchClassifications = async() => {
      classifications.value = (await getClassifications()).data;
    };

    const fetchBlocks = async() => {
      blocks.value = (await getBlocks({ query: { pageSize: MAX_PER_PAGE } })).data;
    };

    const submitForm = () => {
      formErrors.value = {};
      const form = unref(formRef);

      form && form.validate((valid: boolean) => {
        if (!Array.isArray(formValues.value.classificationIds) && (formValues.value.classificationIds !== null)) {
          formValues.value.classificationIds = [Number(formValues.value.classificationIds)];
        }
        formValues.value.sort = Number(formValues.value.sort);

        if (valid) {
          if (isEdit.value) {
            update({ topicId, data: formValues.value }, {
              onSuccess() {
                ElMessage.success(UPDATED_SUCCESSFULLY_TEXT);
                router.go(-1);
              },
              onError(error: any) {
                ElMessage.error(error.response?.data.message);
                formErrors.value = error.response?.data.message;
              }
            });
            return;
          }

          create({ data: formValues.value }, {
            onSuccess() {
              ElMessage.success(CREATED_SUCCESSFULLY_TEXT);

              formValues.value = DEFAULT_FORM_VALUES;
              router.go(-1);
            },
            onError(error: any) {
              ElMessage.error(error.response?.data.message);
              formErrors.value = error.response?.data.message;
            }
          });
        }
      });
    };

    onMounted(() => {
      if (!isEdit.value) {
        isClassificationLoading.value = false;
        isFormLoading.value = false;
      }

      fetchClassifications();
      fetchBlocks();
    });

    return {
      formRef,
      formValues,
      rules,
      isCreating,
      isUpdating,
      formErrors,
      classifications,
      blocks,
      types,
      styles,
      page,
      isFormLoading,
      isClassificationLoading,
      TopicType,
      thumbnailPhotoSource,
      bannerPhotoSource,
      borderPhotoSource,
      handleWorkIdChange,
      handleToggleWorks,
      handleChangeTopWorkIds,
      handleThumbnailPhotoCrop,
      handleBannerPhotoCrop,
      handleBorderPhotoCrop,
      handleClassificationIdsChange,
      bindFormItemError,
      submitForm
    };
  }
});
