
import {
  computed,
  ComputedRef,
  defineComponent,
  onMounted,
  PropType,
  ref,
  toRefs,
  watch
} from 'vue';
import SearchBox from '@/components/keyword-searching/Index.vue';
import Filter, { FilterEvent, FilterOption, FilterType } from '@/components/filter/Index.vue';
import { useTopicWorks } from '@/composables/api';
import { getGenres, getTags, IntBoolean } from '@/services/api';
import { DEFAULT_PAGE_SIZE, MAX_PER_PAGE } from '@/services/api/constants';
import { Genre, Tag, WorkStatus } from '@/interfaces';
import { filter, get, cloneDeep } from 'lodash';
import {
  ElMessage
} from 'element-plus';
import { useKeyword, usePage } from '@/composables';

interface WorkMetaInfo {
  workId: number
  isTopicWork: boolean
}

interface SwitchChangeEvent {
  row?: any
  workId?: number
  isChosen?: boolean
}

export type SelectWorkEvent = number[];

export default defineComponent({
  components: {
    SearchBox,
    Filter
  },
  props: {
    value: {
      type: Object,
      default: () => null
    },
    classificationIds: {
      type: Array as PropType<number[]>,
      default: () => ([])
    }
  },
  emits: ['change', 'toggle', 'changeTopWorkIds'],
  setup(props, { emit }) {
    const { keyword } = useKeyword();
    const { setPageQuery, page, pageSize } = usePage(1, DEFAULT_PAGE_SIZE);

    const { value: topicFormValues, classificationIds } = toRefs(props);
    const status = ref(WorkStatus.PUBLISHED);
    const workMetaInfos = ref<WorkMetaInfo[]>([]);
    const chosenWorkIds = ref<number[]>([]);
    const topWorkIds = ref<string[]>([]);
    const selectAll = ref(true);

    const genreId = ref();
    const genres = ref<Genre[]>([]);
    const genreOptions = computed(() => genres.value.map(({ name, id }) => ({ label: `${name}`, value: `${id}` })));
    const tagId = ref();
    const isChoose = ref();
    const tags = ref<Tag[]>([]);
    const tagOptions = computed(() => tags.value.map(({ name, id }) => ({ label: `${name}`, value: `${id}` })));

    const { data: worksData, isLoading, isFetching, error } = useTopicWorks({ page, pageSize, keyword, status, genreId, tagId, isChoose, classificationIds, topicId: topicFormValues.value.id });

    watch(error, () => {
      ElMessage.error('必須要有主類型!');
    });

    const fetchGenres = async() => {
      genres.value = (await getGenres({ query: { pageSize: MAX_PER_PAGE } })).data;
    };

    const fetchTags = async() => {
      tags.value = (await getTags({ query: { pageSize: MAX_PER_PAGE } })).data;
    };

    const filterOptions: ComputedRef<FilterOption[]> = computed(() => {
      return [
        {
          type: FilterType.SELECTOR,
          label: '影片類型',
          placeholder: '請選擇',
          options: genreOptions.value
        },
        {
          type: FilterType.SELECTOR,
          label: '標籤',
          placeholder: '請選擇',
          options: tagOptions.value
        },
        {
          type: FilterType.SELECTOR,
          default: (topicFormValues.value.id) ? `${IntBoolean.TRUE}` : undefined,
          label: '選擇影片',
          placeholder: '請選擇',
          options: [
            {
              label: '開啟',
              value: `${IntBoolean.TRUE}`
            },
            {
              label: '關閉',
              value: `${IntBoolean.FALSE}`
            }
          ]
        }
      ];
    });

    const handleFilterChange = (event: FilterEvent) => {
      // mutate ref
      genreId.value = event[0];
      tagId.value = event[1];
      isChoose.value = (event[2] === '') ? undefined : event[2];
    };

    const toggleAllWorks = (tableData) => {
      if (workMetaInfos.value.some((workMetaInfo) => {
        return workMetaInfo.isTopicWork === false;
      })) {
        workMetaInfos.value = tableData?.map(({ id }) => ({
          workId: id,
          isTopicWork: true
        }));

        workMetaInfos.value.forEach((workMetaInfo) => {
          if (chosenWorkIds.value.every((work: number) => work !== workMetaInfo.workId)) {
            chosenWorkIds.value.push(workMetaInfo.workId);
          }
        });

        selectAll.value = false;

        emit('toggle', chosenWorkIds.value);
        return;
      }

      if (workMetaInfos.value.every((workMetaInfo) => {
        return workMetaInfo.isTopicWork === true;
      })) {
        workMetaInfos.value = tableData?.map(({ id }) => ({
          workId: id,
          isTopicWork: false
        }));

        workMetaInfos.value.forEach((workMetaInfo) => {
          chosenWorkIds.value = chosenWorkIds.value.filter((id) => {
            return id !== workMetaInfo.workId;
          });
        });

        selectAll.value = true;
      }

      emit('toggle', chosenWorkIds.value);
    };

    const handleSwitchChange = async({ workId, isChosen }: SwitchChangeEvent) => {
      if (isChosen) {
        if (chosenWorkIds.value.every((work: number) => work !== workId)) {
          chosenWorkIds.value.push(workId);
        }
      }

      if (!isChosen) {
        chosenWorkIds.value = chosenWorkIds.value.filter((id) => {
          return id !== workId;
        });
      }

      emit('change', chosenWorkIds.value);
    };

    const handleTopWorkChange = (id: string) => {
      if (topWorkIds.value.includes(id)) {
        topWorkIds.value = filter(topWorkIds.value, workId => id !== workId);
        return;
      }
      topWorkIds.value = [...topWorkIds.value, id];
    };

    watch(topWorkIds, (newVal) => {
      emit('changeTopWorkIds', newVal);
    });

    watch(worksData, async(workResponse) => {
      workMetaInfos.value = workResponse?.data?.map(({ id }) => ({
        workId: id,
        isTopicWork: topicFormValues.value.workIds?.some((workId: number) => workId === id)
      }));
      chosenWorkIds.value = topicFormValues.value.workIds;
    }, { immediate: true });

    onMounted(() => {
      fetchGenres();
      fetchTags();
      topWorkIds.value = cloneDeep(get(topicFormValues, 'value.topWorkIds', []));
      isChoose.value = (topicFormValues.value.id) ? `${IntBoolean.TRUE}` : undefined;
    });

    return {
      page,
      worksData,
      topWorkIds,
      isLoading,
      isFetching,
      workMetaInfos,
      filterOptions,
      selectAll,
      keyword,
      handleSwitchChange,
      handleFilterChange,
      toggleAllWorks,
      handleTopWorkChange
    };
  }
});
